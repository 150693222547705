html, body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

html {
  background-color: #FAFBFF;
  font-size: 10px;

  body {
    font-family: 'Biko', serif;
    min-height: 100vh;
    font-size: 16px;
    background-color: #FAFBFF;
  }

  a, a:visited {
    color: #5468ff
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

@font-face {
  font-family: 'Averta CY';
  font-style: normal;
  font-weight: 200;
  src: local('Gilroy'), url('../fonts/Averta-Cyrillic-Regular-400.woff') format('woff');
}

@font-face {
  font-family: 'Averta CY';
  font-style: normal;
  font-weight: 300;
  src: local('Gilroy'), url('../fonts/Averta-Cyrillic-Regular-400.woff') format('woff');
}

@font-face {
  font-family: 'Averta CY';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy'), url('../fonts/Averta-Cyrillic-Regular-400.woff') format('woff');
}

@font-face {
  font-family: 'Averta CY';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy'), url('../fonts/Averta-Cyrillic-SemiBold-600.woff') format('woff');
}

@font-face {
  font-family: 'Averta CY';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy'), url('../fonts/Averta-Cyrillic-SemiBold-600.woff') format('woff');
}

@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Biko_Black.otf') format('woff'), url('../fonts/Biko_Black.otf') format('woff');
}

@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Biko_Bold.woff') format('woff'), url('../fonts/Biko_Bold.otf') format('otf');
}

@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Biko_Light-Restricted.otf') format('otf'), url('../fonts/Biko_Light-Restricted.woff') format('woff');
}

@font-face {
  font-family: 'Biko';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Biko_Regular.woff') format('woff'), url('../fonts/Biko_Regular.otf') format('otf');
}
